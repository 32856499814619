import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

export const onInitialClientRender = () => {
    setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
      document.getElementById("___gatsby").classList.remove("blured");
    }, 2000);
}


export const onRouteUpdate = ({ location, prevLocation }) => {
  if(prevLocation !== null){
    if (prevLocation.pathname !== location.pathname) {
      document.getElementById("___loader").style.display = "flex";
      document.getElementById("___gatsby").classList.add("blured");

      setTimeout(function() {
        document.getElementById("___loader").style.display = "none";
        document.getElementById("___gatsby").classList.remove("blured");
      }, 1200)
    }
  }

  if(location.pathname === 'portfolio'){
    document.getElementById("___loader").style.display = "flex";
    document.getElementById("___gatsby").classList.add("blured");

    setTimeout(function() {
      document.getElementById("___loader").style.display = "none";
      document.getElementById("___gatsby").classList.remove("blured");
    }, 1200)
  }

}